import * as bootstrap from 'bootstrap';

import { FlashMessage, AjaxModal, VisibilityMap } from '@epigenesys/epi-js';

AjaxModal.start();
VisibilityMap.start();

window.FlashMessage = FlashMessage;

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => {
    new bootstrap.Tooltip(element, {
      sanitize: false
    })
  })
});